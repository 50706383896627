/* .dx-drawer-panel-content {
} */

.dx-drawer-shrink .dx-drawer-panel-content {
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.20);
    border-radius: 5px;

    /* calc(100%-300px) !important;*/
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
    /* color: blue !important; */
    font-weight: 900 !important;
}

.dx-menu.dx-rtl .dx-menu-item-has-icon.dx-menu-item-has-submenu .dx-icon {
    margin: 0 19px 0 3px;
}

span.dx-menu-item-text {
    margin-right: 4px;
}

.dx-rtl.dx-toolbar .dx-toolbar-before {
    padding-left: 0;
}

header.header-component .dx-toolbar-before {
    width: 100%;
}

.unigrid-title {
    font-weight: 700;
    font-size: 1.1rem;
    margin: 0.2rem;
    color: #444
}

.dx-rtl .dx-treeview-node-container .dx-treeview-node .dx-treeview-item .dx-treeview-item-content>.dx-icon {
    margin: 5px !important;
    padding: 0;
    width: 30px !important;
    height: 20px !important;
}

.dx-treeview-item .dx-treeview-item-content span {
    padding: 0;
}

.dx-treeview-item.dx-state-hover {
    background-color: aliceblue !important;
}

.app .content {
    /* background-image: url('/public/images/bgtile.png'); */
}

.dx-overlay-content .dx-popup-content {
    /* height: fit-content !important; */
}

.dx-tagbox-popup-wrapper .dx-overlay-content.dx-rtl.dx-popup-normal.dx-resizable {
    overflow: scroll;
}

.ka-no-padding .dx-button-content {
    padding: 0px;
}

.ka-tarikh-picker input {
    padding: 7px 9px 8px;
    height: 100%;
    width: 100%;
}

div:has(>.ka-request-no .ka-align-middle) {
    justify-content: center;
    font-weight: 1000;
    /* font-size: 1.1rem; */
}

.ka-top-buttons {
    background-color: transparent;
    padding: 0 0.3rem;
}

.ka-shaddowed {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.20);
    border-radius: 5px !important;
}

.ka-colorfull-tabpage {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.20);
    border-radius: 5px !important;
    padding-top: 5px;
}

.ka-colorfull-tabpage .dx-tab {
    padding: 4px;
}


.ka-colorfull-tabpage .dx-tab .dx-icon {
    margin: 0;
}

.ka-colorfull-tabpage .dx-tabpanel-tabs .dx-item.dx-tab {
    border-radius: 10px 10px 0 0;
}


.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines>td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td {
    background-color: transparent;
}

.ka-colorfull-tabpage .dx-tab.dx-tab-selected,
.ka-colorfull-tabpage .dx-item-selected {
    background-color: #3395e9;
}

.ka-colorfull-tabpage .dx-tabpanel>.dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
    color: white;
}

.app .layout-body {
    width: 100%;
}

.ka-no-background .dx-item-selected {
    background-color: transparent !important;
}

.ka-highlight-on-hover:hover {
    opacity: 50%;
}

td .dx-button-has-icon .dx-button-content {
    padding: 0 !important;
}

.dx-treelist-content .dx-treelist-table .dx-row>td,
.dx-treelist-content .dx-treelist-table .dx-row>tr>td {
    vertical-align: middle !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    vertical-align: middle !important;
}

/* .dx-item-content.dx-accordion-item-title {
    background-color: #3395e9;
} */

/* .dx-icon {
    font-family: 'DXIcons' !important;
}

.dx-icon-font * {
    font-family: 'DXIcons' !important;
} */

/* .side-nav-outer-toolbar .dx-drawer {
     height: calc(100% - 70px); 
} */