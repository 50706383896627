.ka_right_accordion .dx-list-item-content {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.dx-accordion-item-title::before {
    content: "" !important;
}

.dx-accordion-item-opened>.dx-accordion-item-title::before {
    content: "" !important;
}

.dx-accordion-item-body {
    padding: 0 10px;
}

/* .ka_right_accordion {
    background-color: whitesmoke;
} 
*/