/* start small */
.small-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0;
  margin: 0; */
  /* min-height: 100vh; */
  /* background-color: rgba(52, 73, 94, 0.10); */

}

.small-loader-container {
  position: relative;
  /* height: 80px;
  width: 80px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  animation: rotate 4s ease-in-out infinite;
  position: absolute;
}

/* .small-load-1 {
  border-top-color: #bc1200;
  height: 50px;
  width: 50px;
  top: -8px;
  left: -8px;
}
 */
.small-load-2 {
  border-top-color: #e86d00;
  height: 38px;
  width: 38px;
  top: -7px;
  left: -9px;
  animation-duration: 2s;
}

.small-load-3 {
  border-top-color: #0076c4;
  height: 38px;
  width: 38px;
  top: -7px;
  left: -9px;
  animation-duration: 1s;
}

.small-loading-container img {
  /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(213deg) brightness(109%) contrast(101%); */
  filter: invert(26%) sepia(15%) saturate(1016%) hue-rotate(169deg) brightness(98%) contrast(96%);
  width: 30px;
  /* margin-bottom: 56px;
  margin-left: -27px; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* end small */
/* start medium */
.medium-loading-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* padding: 0;
  margin: 0; */
  /* min-height: 100vh; */
  /* background-color: rgba(52, 73, 94, 0.10); */

}

.medium-loader-container {
  position: relative;
  /* height: 80px; */
  /* width: 80px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.medium-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  animation: rotate 4s ease-in-out infinite;
  position: absolute;
}

/* .medium-load-1 {
  border-top-color: #bc1200;
  height: 50px;
  width: 50px;
  top: -8px;
  left: -8px;
} */

.medium-load-2 {
  border-top-color: #e86d00;
  height: 80px;
  width: 80px;
  top: -10px;
  left: -15px;
  animation-duration: 2s;
}

.medium-load-3 {
  border-top-color: #0076c4;
  height: 80px;
  width: 80px;
  top: -10px;
  left: -15px;
  animation-duration: 1s;
}

.medium-loading-container img {
  /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(213deg) brightness(109%) contrast(101%); */
  filter: invert(26%) sepia(15%) saturate(1016%) hue-rotate(169deg) brightness(98%) contrast(96%);
  width: 60px;
  /* margin-bottom: 28px;
  margin-left: 4px; */
  align-items: center;

}

/* @keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */
/* end medium */

/* start larg */
.large-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  /* min-height: 100vh; */
  /* background-color: rgba(52, 73, 94, 0.10); */

}

.large-loader-container {
  position: relative;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  animation: rotate 4s ease-in-out infinite;
  position: absolute;
}

.large-load-1 {
  border-top-color: #bc1200;
  height: 200px;
  width: 200px;
  top: 0;
  left: 0;
}

.large-load-2 {
  border-top-color: #e86d00;
  height: 250px;
  width: 250px;
  top: -25px;
  left: -25px;
  animation-duration: 2s;
}

.large-load-3 {
  border-top-color: #0076c4;
  height: 300px;
  width: 300px;
  top: -50px;
  left: -50px;
  animation-duration: 1s;
}

.large-loading-container img {
  /* filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(213deg) brightness(109%) contrast(101%); */
  filter: invert(26%) sepia(15%) saturate(1016%) hue-rotate(169deg) brightness(98%) contrast(96%);
  width: 140px;
}

/* @keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */

/* end larg */