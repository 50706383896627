.compressed_items .dx-list-item-content,
.compressed_items input
{
    padding: 0 1rem !important;
    min-height: 2em;
}

// .compressed_items .dx-list{
//     padding: 0 !important;
// }

.compressed_items .dx-item-content.dx-accordion-item-title{
    //padding: 1rem 5px;
    line-height: normal;
    background-color: bisque;
}

.compressed_items .dx-list-group-header{
    // padding:5px;
    // background-color: whitesmoke;
    text-align: center;
    font-weight: 900;
    border: 5px solid transparent;
}

.prop_label{
    
}

.dx-list-group-header{
    padding:0 !important;
    border: 5px solid transparent;
}