.nobr {
    white-space: nowrap;
    font-family: 'Times New Roman';
    font-style: italic;
 }
 .supsub {
    display: inline-block;
    margin: -9em 0;
    vertical-align: -0.55em;
    line-height: 1.35em;
    font-size: 70%;
    text-align: left;
    font-family: 'Times New Roman';
    font-style: normal;
 }